import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function OttawaCompanyDeliversHelp () {
  const shareTitle = 'Ottawa company delivers help to businesses'
  const articleLink = 'https://ottawa.ctvnews.ca/ottawa-company-delivers-help-to-businesses-with-same-day-drop-off-1.5178336'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Ottawa company delivers help to businesses with same-day drop-off</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>November 6, 2020</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/ctv-overflow.jpg" alt="CTV Preview" />
                <div className="snippet">The following preview is from <a href={articleLink} target="_blank" rel="noreferrer">CTV Ottawa</a></div>
                <p>OTTAWA — Online sales continue to boom as more businesses move online in order to adapt for life in a pandemic.</p>
                <p>Now, one local company is delivering help, providing same-day drop-off at a price local shops can afford in order to compete with major market players. </p>
                <p>Trexity is now providing local shipments for 30 stores in Ottawa and has also expanded to other cities across Canada. </p>
                <a href={articleLink} target="_blank" rel="noreferrer">Read full article on CTV Ottawa</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/love-local-ottawa-trexity" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Love Local Ottawa interviews Trexity on CTV Morning News</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
